import React, { useState, useEffect, useMemo } from 'react';
import { Dialog, Box } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { formatFullName } from '../../utils/formatFullName';

import {
    useDataProvider,
    Notification,
    Form,
    useNotify,
    minLength,
    maxLength,
    TextInput, required, email,
} from "react-admin";


import { CssBaseline, Button, Link, Grid } from "@mui/material";

import { Typography } from "@mui/material";

import { makeStyles, withStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';

import { Mixpanel } from '../../analytics';

const validateFirstName = [required(), minLength(2), maxLength(30)];
const validateEmail = [required(), email()];

const useStyles = makeStyles()(theme => ({
    player: {
        display: 'block',
    },
}));

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}));

const validateFields = (values) => {
    const errors = {};
    if (!values.first_name) {
        errors.first_name = 'The First Name is required';
    }
    return errors
}

const SendInviteDialog = ({ open, setOpen }) => {

    const [email, setEmail] = useState("");
    const [first_name, setFirstName] = useState("");


    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { classes } = useStyles();
    // const [open, setOpen] = useState(false);

    function handleClose(close) {
        if (close) {
            setOpen(false);
        }
    }
    const handleSubmit = async values => {
        try {
            const data = {}
            data["first_name"] = first_name || ''
            data["email"] = email || ''
            await dataProvider.create(
                `coaches/send_invite`,
                { data }
            );

            notify("Invite sent succesfully.", { type: "success", undoable: "false" });
            handleClose(true)

        } catch (e) {
            notify(e.message, "warning");
            handleClose(true)
        }
    };


    return (
        <Dialog
            open={open}
            maxWidth={false}
            fullWidth={false}
            onClose={() => handleClose()}
        >
            <DialogContent >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(true)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Form classes={classes.grid} onSubmit={handleSubmit} validate={validateFields}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Typography variant="h5" mb={"1em"} >
                            Send LIA Invite to:
                        </Typography>
                        <TextInput
                            label="First Name"
                            value={first_name}
                            validate={validateFirstName}
                            source={first_name}
                            onChange={e =>
                                setFirstName(e.target.value)
                            }
                        />

                        <TextInput
                            label="Email"
                            source={email}
                            type="email"
                            value={email}
                            validate={validateEmail}
                            onChange={e =>
                                setEmail(e.target.value)
                            }
                        />

                        <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                            *Note: Please make sure to verify client name and email, before sending.
                        </Typography>
                    </Box>

                    <Box mt="1em" />
                    <Box
                        display="flex"
                        justifyContent="flex-end"

                    >
                        <Button
                            variant="text"
                            color="primary"
                            sx={{ m: .5 }}
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ m: .5 }}
                            type="submit"
                            onClick={handleSubmit}
                        >
                            Send
                        </Button>
                    </Box>
                    <Notification />

                </Form>
            </DialogContent>
        </Dialog >
    );
};

export default SendInviteDialog;
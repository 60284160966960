import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  List,
  Datagrid,
  EmailField,
  DateField,
  Filter,
  TextInput,
  usePermissions,
  useRecordContext,
  useGetIdentity,
  FilterButton,
  ExportButton,
  useDataProvider,
  useListContext,
  Toolbar,
  Button,
  useRefresh,
  ReferenceInput,
  SelectInput,
  useNotify,
  CreateButton,
  Form,
  SimpleForm,
  EditButton,
  SelectField,
  BulkDeleteWithUndoButton,
  BulkDeleteWithConfirmButton,
  BulkUpdateButton,
  BulkDeleteButton,
  useRedirect
} from "react-admin";
import { roles } from "../../config";
import { Autocomplete, Box, Dialog, DialogActions, DialogContent, FormControl, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import ClientLoading from "./ClientLoading";
import WelcomeTitle from '../common/WelcomeTitle';

import { Stack } from '@mui/material';
import { ChevronLeft, ChevronRight, Send } from "@mui/icons-material";
import Moment from "react-moment";
import { useAppContext } from "../../contexts/AppContext";
import classNames from "classnames";
import { CoacheSelect } from "./CoacheSelect/CoacheSelect";
import { ImportButton } from "react-admin-import-csv";
import { DatagridHeader } from "../ui/DatagridHeader";
import { exportClientsToXLSX } from "../../utils/exportClientsToXLSX";

import SendInviteDialog from "./SendInviteDialog"

export const useListStyles = makeStyles()(() => ({
  costom_checkox: {

    position: 'absolute',
    left: 16,
    top: 16,
    zIndex: 100,
    width: '24px',
    height: 24,
    border: '1px solid black',
    background: 'transparent',
    outline: 'none'
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: -8,
    marginBottom: -8
  },
  chip: { margin: 4 },
  table: {
    padding: '24px',
    borderRadius: '12px',
    marginTop: '32px',
    backgroundColor: "white",
    border: 'none',
    outline: 'none',
    height: '100%',

    'td': {
      padding: '16px 0',
      color: '#5C5C5C',
      textAlign: 'center',
      'a': {
        textDecoration: 'none',

      }

    },
    'span': {
      textAlign: 'center',
    },
    'th': {
      padding: '16px 0',
      textAlign: 'center',
      fontSize: '16px',
      color: '#232323',
      fontWeight: '600',
      flexDirection: 'column',
    },

    '.RaList-content': {
      boxShadow: 'none',

    },
    '.RaBulkActionsToolbar-toolbar': {
      display: 'none'
    },

  },
  table_admin: {
    'th:nth-child(2)': {
      'span': {
        textAlign: 'start',
        width: '100%',
        display: 'block'
      },
    },
    'td:nth-child(2)': {
      'span': {
        justifyContent: 'start'
      },
    },
    'th:nth-child(3)': {
      // display: 'block',
      paddingRight: '32px',

    },
    'td:nth-child(3)': {
      // display: 'block',

      paddingRight: '32px',
    },
    'th:nth-child(4)': {
      'span': {
        textAlign: 'start',
        width: '100%',
        display: 'block'
      },
    },
    'td:nth-child(4)': {
      textAlign: 'start',
    },

  },
  table_coach: {
    'th:nth-child(2)': {
      'span': {
        textAlign: 'start',
        width: '100%',
        display: 'block'
      },
    },
    'th:nth-child(3)': {
      'span': {
        textAlign: 'start',
        width: '100%',
        display: 'block',
      },
    },
    'td:nth-child(3)': {
      textAlign: 'start',
      span: {
        justifyContent: 'start !important',
      },
    },
    'td:nth-child(2)': {
      'span': {
        justifyContent: 'start'
      },
    },
  },
  table_coaches: {
    'th:nth-child(4)': {
      'span': {
        textAlign: 'start',
        width: '100%',
        display: 'block'
      },
    },
    'td:nth-child(4)': {
      justifyContent: 'start',
      textAlign: 'start',
      'span': {
        justifyContent: 'start',
        textAlign: 'start',
      },
    },
    'th:nth-child(3)': {
      'span': {
        textAlign: 'start',
        width: '100%',
        display: 'block',
      },
    },
    'td:nth-child(3)': {
      textAlign: 'start',
      span: {
        justifyContent: 'start !important',
      },
    },

  },
  filters: {
    display: 'grid',
    gap: '16px',
    marginBottom: '24px',
    gridTemplateColumns: 'auto 1fr',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    height: '48px',
    '.MuiTextField-root': {
      marginTop: '0 !important'
    },
    '.MuiFormControl-root, .RaFilter-form': {
      height: '48px',
      minHeight: '48px',
    },
    '.RaFilterFormInput-hideButton': {
      marginBottom: '4px !important',

    },
    '.selectInput': {
      height: '48px',
      width: '200px',
      position: 'relative',
      '.MuiInputBase-input': {
        width: '150px',
        height: '48px',


      },
      '.MuiOutlinedInput-root': {
        height: '48px',

      },
      '.MuiSelect-outlined': {
        outline: 'none',
      },
      '.MuiSelect-select': {
        height: '48px',
      },

    },
  },
  stack: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'end',
  },
  stack_right_buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '16px',
    position: 'relative',
  },
  stack_right_button: {
    color: '#003E6F',
    textTransform: 'uppercase',
    fontWeight: '600',


    'span': {
      margin: '0',
    }
  },
  stack_right_button_delete: {
    color: '#D32F2F',
    marginRight: '16px',
    'span': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '1px',
      height: '36px',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '-16px',
      background: '#E2E2E2',
    },

  },
  filter_button: {

    borderRadius: '4px',
    height: "48px",
    width: "107px",
    color: "#003E6F",
    cursor: "pointer",
    border: '1px solid #E2E2E2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'uppercase',
    gap: '10px',
    marginRight: 'auto',

    '#text': {
      color: 'red',
    }
  },
  export_button: {
    borderRadius: '4px',
    height: "36px",
    width: "136px",
    background: '#003E6F',
    cursor: "pointer",
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: '#003E6F',
      color: 'white',
    },

    a: {

      textTransform: 'unset',
      '&:hover': {
        backgroundColor: '#003E6F',
        color: 'white',
      },
    },
    svg: {
      fill: 'white'

    },

  },
  create_button: {
    borderRadius: '4px',
    height: "36px",
    width: "136px",
    background: 'transparent',
    border: '1px solid #003E6F',
    cursor: "pointer",
    color: '#003E6F',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'unset',
    svg: {
      textTransform: 'unset',
      fill: '#003E6F'

    },
  },
  invite_button: {
    borderRadius: '4px',
    height: "36px",
    width: "136px",
    background: 'transparent',
    border: '1px solid #003E6F',
    cursor: "pointer",
    color: '#003E6F',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'unset',
    span: {
      textTransform: 'unset',
      fontSize: '14px',
      fontWeight: '600',
    },
    svg: {
      fill: '#003E6F'

    },
  },
  import_button: {
    borderRadius: '4px',
    height: "36px",
    width: "136px",
    background: 'transparent',
    border: '1px solid #003E6F',
    cursor: "pointer",
    color: '#003E6F',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'unset',
    span: {
      textTransform: 'unset',
      fontSize: '14px',
      fontWeight: '600',
    },
    svg: {
      fill: '#003E6F'
    },
  },
  select: {

    'fieldset': {
      outline: 'none',
      border: 'none',
    }
  },
  input: {
    width: '320px',
    height: '48px',
    fontSize: '14px',
    fontWeight: '600',
    border: '2px solid #E2E2E2',
    borderRadius: '4px',
    padding: '0 16px',
    outline: 'none',
    color: '#003E6F',



    '.MuiInputBase-formControl': {
      paddingLeft: '0'
    },
    '.MuiInputBase-input': {
      padding: '11px 14px',
      paddingLeft: '0',
      '&::placeholder': {
        color: '#667085',
        opacity: '1',
      },
    },

    '.MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
      outline: 'none',
    },
    'label': {
      color: '#232323',
      background: 'white',
      padding: '0 5px',
    },

  },
  name_field: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    color: '#232323',
    justifyContent: 'center',

    'img': {

      width: '40px',
      height: '40px',
      borderRadius: '50%',
    }
  },
  score_field: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    color: '#232323',
    justifyContent: 'center',

  },
  coache_field: {
    padding: '7px 18px',
    background: 'rgba(33, 150, 243, 0.15)',
    color: '#003E6F',
    borderRadius: '100px',
    whiteSpace: 'nowrap',
  },
  coache_field_no_coache: {
    background: '#F2F2F2 !important',
  },
  toolbar: {
    background: 'white',
    justifyContent: 'end',
    padding: '0 !important',
  },
  toolbar_button: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    maxWidth: '40px',

    'span': {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      margin: '0',
    }

  },
  change_coache_dialog: {
    width: '440px',
    padding: '16px 24px 20px 24px',
  },
  modal_title: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#232323',
    marginBottom: '16px',
  },
  inputs_dialog: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  input_dialog: {
    height: '48px',
    maxHeight: '48px',
    width: '100%',
    '.MuiFormLabel-root': {
      color: 'black',
    },
    'input': {
      color: '#667085',
      '-webkit-text-fill-color': '#667085 !important'
    },

    '.MuiInputBase-input': {
      paddingBlock: '0',
      height: '48px',
    },
    '.MuiOutlinedInput-root': {
      height: '48px',
      paddingBlock: '0'
    },
    '.MuiAutocomplete-input': {
      paddingBlock: '0 !important'

    },
  },
  input_dialog_full: {
    gridColumn: '1 / 3'

  },
  modal_buttons: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '16px',
  },
  form: {
    padding: '0',
    paddingBottom: '28px',
  },
  modal_button_cancel: {
    background: 'transparent',
    border: 'none',
    outline: 'none',
    color: '#5C5C5C',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  modal_button_assign: {
    background: '#667085',
    border: 'none',
    outline: 'none',
    color: 'white',
    borderRadius: '4px',
    padding: '6px 16px',
    height: '36px',
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  actions_field: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    color: '#003E6F',
    textDecoration: 'underline #003E6F',
    justifyContent: 'center'
  },
  actions_field_red: {
    color: '#EE613F',
    textDecoration: 'underline #EE613F',
  },
  actions_field_button: {
    outline: 'none',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
  },
}));

export const NameField = () => {
  const { classes } = useListStyles();
  const record = useRecordContext();

  return <span className={classes.name_field}>
    <img src='/Smiling_Face.png' alt='profile_image' />

    {`${record.firstName || record.first_name || ''} ${record.lastName || record.last_name || ''}`}
  </span>
};
const LatestLifeScoreField = ({ lifeScoreState }) => {

  const { classes } = useListStyles();
  const record = useRecordContext();
  const currentScore = lifeScoreState?.find(item => item.userId === record.user_id)
  return <span className={classes.score_field}>
    {currentScore?.lifeScore}%
  </span>

}

const CompateProgressButton = () => {
  const redirect = useRedirect();
  const record = useRecordContext();
  const { classes } = useListStyles();
  return <span className={classes.actions_field}>
    <Button
      // variant="contained"
      // color="primary"
      // className={classes.actions_field_button}
      onClick={(e) => {
        e.stopPropagation();
        redirect(`/clients/compare/${record.id}`)
      }}
    >
      Compare Progress
    </Button>
  </span>
}

const DateListField = ({ source }) => {

  const { classes } = useListStyles();
  const record = useRecordContext();
  return <span className={classes.date_field}>
    <Moment format="MM/DD/YYYY">
      {`${record[source]}`}


    </Moment>
  </span>

}

const CoachField = () => {
  const { coaches } = useAppContext();
  const record = useRecordContext();

  const currentCoache = useMemo(() => {
    const coach = coaches?.find(coache => +coache.id === +record.coach_id)
    return `${coach?.first_name || ''} ${coach?.last_name || ''}`.trim()
  }, [coaches, record.coach_id])
  const { classes } = useListStyles();
  return <span className={classNames(classes.coache_field, {
    [classes.coache_field_no_coache]: !currentCoache
  })}>
    {`${currentCoache || 'Not assigned yet'}`}


  </span>

}

const ChangeCoacheDialog = ({ open, setIsOpenDialog, setEditRecord, record }) => {
  const { classes } = useListStyles();
  const [inputValue, setInputValue] = useState('');
  const { coaches } = useAppContext();
  const [coach_id, setCoachId] = useState(record?.coach_id || 0);
  useEffect(() => {
    setCoachId(record?.coach_id || 0)

  }, [record?.coach_id])
  const currentCoach = coaches?.find(item => item.id === coach_id) || null;
  const renderCoaches = useMemo(() => {
    if (inputValue.length <= 2) {
      return []

    }
    const newCoaches = coaches.filter(item => {
      return item.role !== 1 && `${item.first_name} ${item.last_name}`.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    })
      .map((item) => ({ label: `${item.first_name} ${item.last_name}`, id: item.id, role: item?.role }));
    return newCoaches;

  }, [coaches, inputValue])

  const coachValue = useMemo(() => `${currentCoach?.first_name || ''} ${currentCoach?.last_name || ''}`.trim(),
    [currentCoach?.first_name, currentCoach?.last_name])

  const onClose = useCallback(() => {
    setIsOpenDialog(false)
    setEditRecord(null)
  }, [setEditRecord, setIsOpenDialog])
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const handleSubmit = async values => {
    try {
      const data = {}
      data["coach_id"] = coach_id || ''
      await dataProvider.update(
        `clients/${record.id}`,
        { data }
      );
      notify("Coach succesfully changed.", { type: "success", undoable: "false" });
      refresh()
      onClose();
      setCoachId(coach_id || 0);
    } catch (e) {
      notify(e.message, "warning");
    }


  };

  return <Dialog
    open={open}
    onClose={onClose}
    className=''
  >
    <DialogContent className={classes.change_coache_dialog}>
      <Typography className={classes.modal_title}>Assign Coach</Typography>
      <SimpleForm className={classes.form} toolbar={<Box className={classes.modal_buttons}>
        <button className={classes.modal_button_cancel} onClick={onClose}>
          Cancel
        </button>
        <button className={classes.modal_button_assign} onClick={() => { handleSubmit() }}>
          Assign

        </button>
      </Box>}>
        <Box className={classes.inputs_dialog}>
          <TextInput
            source="id"
            disabled
            className={classes.input_dialog}
            defaultValue={record?.id}
          />
          <TextInput
            source="firstName"
            resource="clients"
            disabled
            fullWidth
            defaultValue={record?.firstName}
            className={classes.input_dialog}
          />
          <TextInput
            type="email"
            source="email"
            resource="clients"
            disabled
            fullWidth
            defaultValue={record?.email}

            className={classNames(classes.input_dialog, classes.input_dialog_full)}
          />
          <CoacheSelect
            label='Coach Name'
            setCoachId={setCoachId}
            coachValue={coachValue}
            renderCoaches={renderCoaches}
            inputValue={inputValue}
            setInputValue={setInputValue}
            className={classNames(classes.input_dialog, classes.input_dialog_full)}

          />

        </Box>

        {3 === roles.ADMIN && (
          <ReferenceInput
            label="Coach"
            source="coach_id"
            reference="coaches"
            sort={{ field: 'first_name', order: 'ASC' }}
          >

            {/* <AutocompleteInput
              optionText={optionRenderer}
              onChange={(event, value) => {
                record.coach_id = event
              }}
            /> */}
          </ReferenceInput>
        )}
      </SimpleForm>


    </DialogContent>
  </Dialog >

}
const ActionsField = ({ setEditRecord, setIsOpenDialog }) => {
  const { coaches } = useAppContext();
  const record = useRecordContext();

  const currentCoache = useMemo(() => {
    const coach = coaches?.find(coache => +coache.id === +record.coach_id)
    return `${coach?.first_name || ''} ${coach?.last_name || ''}`.trim()
  }, [coaches, record.coach_id])
  const { classes } = useListStyles();
  //context)
  return <>

    <span onClick={(e) => {
      e.stopPropagation();
      setEditRecord(record)
      setIsOpenDialog(true)
    }} className={
      classNames(classes.actions_field, {
        [classes.actions_field_red]: !record.coach_id,
      })}>
      {record.coach_id ? 'Change Coach' : 'Assign Coach'}
      {/* <button className={classes.actions_field_button}>
          <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83333 15.5V13.8333H2.84167L6.59167 10.0833L5.41667 8.90833L1.66667 12.6583V9.66667H0V15.5H5.83333ZM9.58333 7.09167L13.3333 3.34167V6.33333H15V0.5H9.16667V2.16667H12.1583L8.40833 5.91667L9.58333 7.09167Z" fill="#5C5C5C" />
          </svg>

        </button> */}

    </span>

  </>

}

const postFilters = [
  <ReferenceInput class='referenceInput' label="Clients" source="id" reference="clients" allowEmpty>
    <SelectInput class='selectInput' optionText="firstName" />
  </ReferenceInput>
];
const config = {
  logging: true,
  disableCreateMany: true
}

export const ListToolbar = ({ isCoachList, onClickSendInvite, props }) => {
  const { classes } = useListStyles();
  const listContext = useListContext();
  const dataProvider = useDataProvider();
  const isSelectItems = useMemo(() => listContext.selectedIds.length > 0, [listContext.selectedIds.length])
  const refresh = useRefresh();
  const notify = useNotify();
  const onClickDelete = useCallback(() => {
    Promise.all(listContext.selectedIds.map(async (id, index) => {
      const data = {}
      data["coach_id"] = ''
      return dataProvider.update(
        `clients/${id}`,
        { data }
      );

    })).then((data) => {
      notify("Coach succesfully deleted.", { type: "success", undoable: "false" });
      refresh();

    })

  }, [dataProvider, listContext.selectedIds, notify, refresh])

  const onClickOpen = useCallback(() => {
    listContext.selectedIds.forEach((id, index) => {
      const url = `/clients/${id}`;
      window.open(url);

    });
  }, [listContext.selectedIds])

  return (
    <Box className={classes.filters}>
      <FormControl>
        <Filter {...props}>
          {/* <InputLabel
            id="demo-simple-select-label"
            className={classes.label}
          >
            Search
          </InputLabel> */}
          <TextInput
            className={classes.input}
            label="Search"
            source="q"
            alwaysOn
            placeholder="Name or Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.755 14.2549H14.965L14.685 13.9849C15.665 12.8449 16.255 11.3649 16.255 9.75488C16.255 6.16488 13.345 3.25488 9.755 3.25488C6.165 3.25488 3.255 6.16488 3.255 9.75488C3.255 13.3449 6.165 16.2549 9.755 16.2549C11.365 16.2549 12.845 15.6649 13.985 14.6849L14.255 14.9649V15.7549L19.255 20.7449L20.745 19.2549L15.755 14.2549ZM9.755 14.2549C7.26501 14.2549 5.255 12.2449 5.255 9.75488C5.255 7.26488 7.26501 5.25488 9.755 5.25488C12.245 5.25488 14.255 7.26488 14.255 9.75488C14.255 12.2449 12.245 14.2549 9.755 14.2549Z"
                      fill="#003E6F"
                    />
                  </svg>
                </InputAdornment>
              ),
            }}
          />

          {postFilters}
        </Filter>
      </FormControl>
      <Stack className={classes.stack} direction="row" justifyContent="space-between">
        {/* <FilterForm filters={postFilters} /> */}
        {/* <FilterButton conte title="Filter" label="" className={classes.filter_button} filters={postFilters} /> */}
        <Box className={classes.stack_right_buttons}>
          {isSelectItems && (
            <>
              {/* <Button className={classes.stack_right_button}>
              Action
            </Button> */}
              {/* <Button onClick={() => {
              onClickOpen();
            }} className={classes.stack_right_button}>
              Open in New Tab
      f      </Button> */}
              <Button
                onClick={() => onClickDelete()}
                className={`${classes.stack_right_button} ${classes.stack_right_button_delete}`}
              >
                <svg
                  width="12"
                  height="15"
                  viewBox="0 0 12 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2775 5.68262L10.5275 6.98012L1.43 1.73012L2.18 0.432617L4.46 1.74512L5.48 1.46762L8.7275 3.34262L9.005 4.37012L11.2775 5.68262ZM0.5 13.2501V4.25012H4.3025L9.5 7.25012V13.2501C9.5 13.6479 9.34196 14.0295 9.06066 14.3108C8.77936 14.5921 8.39782 14.7501 8 14.7501H2C1.60218 14.7501 1.22064 14.5921 0.93934 14.3108C0.658035 14.0295 0.5 13.6479 0.5 13.2501Z"
                    fill="#D32F2F"
                  />
                </svg>
                Delete
              </Button>
            </>
          )}
          <Button
            label="Send Invite"
            className={classes.invite_button}
            onClick={(e) => onClickSendInvite()}
            startIcon={<Send />}
          >

          </Button>
          <ExportButton
            label="Export.csv"
            className={isCoachList ? classes.import_button : classes.export_button}
            exporter={isCoachList ? undefined : exportClientsToXLSX}
          />

          {isCoachList && (
            <>
              <div className={classes.import_button}>
                <ImportButton
                  label="Import.csv"
                  title="123123123"
                  {...props}
                  {...config}
                  parseConfig={{ dynamicTyping: true }}
                />
              </div>
              <CreateButton label="Add Coach" className={classes.export_button} />
            </>
          )}
        </Box>

        {/* <CreateButton /> */}
      </Stack >
    </Box >
  );
}
export const PostPagination = ({ setLifeScoreState, isClients }) => {
  const { classes } = useListStyles();
  const listContext = useListContext();
  const assessmentsIds = useMemo(() => {
    if (isClients) {
      return listContext?.data ? [...listContext?.data].map(data => ({
        assesmentId: data?.Surveys[data.Surveys?.length - 1]?.id,
        userId: data?.user_id
      })) : []
    }

    return [];

  }, [isClients, listContext?.data]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (isClients) {
      Promise.all(assessmentsIds.map((item) => {
        return dataProvider.getOne('assessments', { id: item.assesmentId });
      })).then(data => {
        setLifeScoreState(data.map((data, i) => ({ lifeScore: data.data.total_life_score, userId: assessmentsIds[i].userId })))
      })
    }

  }, [assessmentsIds])
  const { page, hasPreviousPage, hasNextPage, setPage, setPerPage, perPage } = listContext;
  return <Toolbar className={classes.toolbar}>

    <Box>
      Rows per page:
      <Select
        className={classes.select}
        value={perPage}
        label="Age"
        onChange={(e) => { setPerPage(e.target.value); }}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={30}>30</MenuItem>
      </Select>
    </Box>
    {listContext.page * listContext.perPage - listContext.perPage + 1}
    -
    {Math.min(listContext.page * listContext.perPage, listContext.total)}
    {' '}
    of
    {' '}
    {listContext.total}
    <Button
      className={classes.toolbar_button}
      disabled={!hasPreviousPage}
      key="previous"
      onClick={() => setPage(page - 1)}
      startIcon={<ChevronLeft />}
    >
      Previous
    </Button>
    <Button
      className={classes.toolbar_button}
      disabled={!hasNextPage}
      key="next"
      onClick={() => setPage(page + 1)}
      startIcon={<ChevronRight />}
    >
      Next
    </Button>
  </Toolbar>
};


export const ClientList = props => {
  const { classes } = useListStyles();
  const { permissions } = usePermissions();
  const isAdmin = permissions === roles.ADMIN;
  const isCoach = permissions === roles.ADMIN || permissions === roles.COACH;
  const { identity } = useGetIdentity();
  const [lifeScoreState, setLifeScoreState] = useState(null)
  const [editRecord, setEditRecord] = useState(null)
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenInviteDialog, setIsOpenInviteDialog] = useState(false);

  const onClickSendInvite = useCallback(() => {
    setIsOpenInviteDialog(true)
  })

  return (
    <>
      {
        isCoach ?
          <>

            <WelcomeTitle
              title={`Welcome `}
              userName={`${identity?.fullName},`}
              subtitle={'Help you clients to achieve their goals and set their priorities according to their true objective of life.'}
            />
            <ChangeCoacheDialog record={editRecord} open={isOpenDialog} setIsOpenDialog={setIsOpenDialog} setEditRecord={setEditRecord} />
            <SendInviteDialog open={isOpenInviteDialog} setOpen={setIsOpenInviteDialog} />

            <List
              className={classNames(classes.table, {
                [classes.table_admin]: isAdmin,
                [classes.table_coach]: !isAdmin,

              })}
              // filters={isCoach ? <ListToolbar /> : null}
              actions={<ListToolbar onClickSendInvite={onClickSendInvite} />}
              // filters={postFilters}
              pagination={<PostPagination isClients setLifeScoreState={setLifeScoreState} />}

              perPage={10}
              {...props}
              sort={{ field: "id", order: "DESC" }}
            >
              <RenderDataGrid isCoach={isCoach} lifeScoreState={lifeScoreState} setEditRecord={setEditRecord} setIsOpenDialog={setIsOpenDialog} />
              {/* <Datagrid
                rowClick={isCoach ? "edit" : "show"}
                rowSx={(record, i, ar) => {
                }}
              >
                <NameField style={{ background: 'red' }} label="Full Name" />
                <EmailField source="email" />
                <DateField style={{ background: 'red' }} source="created_at" />
                <DateField source="updated_at" />
                <LatestLifeScoreField label="Latest Life Score" lifeScoreState={lifeScoreState} />
              </Datagrid> */}
            </List>
          </>
          :
          <ClientLoading {...props} />

      }
    </>
  );
};


const RenderDataGrid = ({ isCoach, lifeScoreState, setEditRecord, setIsOpenDialog }) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions === roles.ADMIN;
  const listContext = useListContext();
  return <>
    <Datagrid
      header={<DatagridHeader />}
      rowClick={() => {
        // return 'compare'
        return isCoach ? "edit" : "show"
      }}
      rowSx={(record, i, ar) => {
        return listContext.selectedIds.includes(record.id) ? { bgcolor: '#2196F334' } : {}
      }}
    >
      <NameField label="Full Name" />
      {isAdmin && <CoachField source="Coach" />}
      <EmailField source="email" />
      <DateListField source="created_at" />
      <DateListField source="updated_at" />
      <LatestLifeScoreField label="Latest Life Score" lifeScoreState={lifeScoreState} />
      {/* <CompateProgressButton label="Action" /> */}
      {isAdmin && <ActionsField source="Notifications & Actions" setEditRecord={setEditRecord} setIsOpenDialog={setIsOpenDialog} />}
    </Datagrid>
  </>

}